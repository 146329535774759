import React, { useState, useMemo, useEffect, useRef } from 'react';
import './App.css';
import './RecipeDetail.css';
import RecipeDetailPopup from './RecipeDetailPopup.js';
import RecipeCards from './RecipeCards.js';
import BgPattern from './images/bg-pattern.jpeg';
import { ReactComponent as IconSavings } from './images/icon-savings.svg';
import { ReactComponent as IconEnvironment } from './images/icon-environment.svg';

import { ReactComponent as InfoIcon } from './images/icon-info.svg';

import ImageTitle from './images/title-vlees-naar-bonen.png';
import imageDish1 from './images/dishes/dish-1.png';
import imageDish2 from './images/dishes/dish-2.png';
import imageDish3 from './images/dishes/dish-3.png';
import imageDish4 from './images/dishes/dish-4.png';
import imageDish5 from './images/dishes/dish-5.png';

function App({ onClose, props }) {
    const defaults = {
        "serves": "4",
    }

    const dishes = [imageDish1, imageDish2, imageDish3, imageDish4, imageDish5];

    const data = {
        meats: {
            1: { 'name': 'Kip(filet)', 'co2': 5.916, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/chicken-breast_2c5db285-6bfb-450f-8c67-d69df0967abe', 'price': 12.58, 'grams': 125 },
            2: { 'name': 'Gerookte kip', 'co2': 5.916, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/chicken-breast_2c5db285-6bfb-450f-8c67-d69df0967abe', 'price': 20.48, 'grams': 125 },
            3: { 'name': 'Rundergehakt', 'co2': 19.613, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/minced-meat-beef_a5d193b0-0c5b-4c8a-8b76-b28ecb8ff6c3', 'price': 9.41, 'grams': 100 },
            4: { 'name': 'Spekjes', 'co2': 6.622, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/pork_f1a8b7a1-5744-4ee3-ae43-019150ea4e43', 'price': 14.18, 'grams': 100 },
        },
        beans: {
            1: { 'name': 'Linzen', 'co2': 1.234, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/lentils_57d53c4d-57a0-4f80-8bad-3dab1cb546c1', 'price': 3.78 },
            2: { 'name': 'Kikkererwten', 'co2': 0.713, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/chickpeas_fd342297-a02b-421b-b741-6d8a113e1e41', 'price': 3.35 },
            3: { 'name': 'Witte bonen', 'co2': 1.29, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/white-beans_2ab775d0-f174-4fa5-a46c-63622eb18957', 'price': 3.42 },
            4: { 'name': 'Kidneybonen', 'co2': 1.198, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/kidney-beans_2be6b86a-9082-4161-b3e3-dbd77f8ebfc6', 'price': 2.73 },
            5: { 'name': 'Bruine bonen', 'co2': 0.714, 'source': 'https://<a hreftarget="_blank" href="https://livelca.com/"">livelca</a>.com/products/beans_4f904f95-e2c1-47d6-a6b6-eab72fa2db8a', 'price': 2.45 },
        }
    }

    const co2ToKm = 0.2017;
    const resultRef = useRef();
    const [randomDish] = useState(dishes[getRandomInt(dishes.length)]);
    const [meatSelection, setMeatSelection] = useState(1);
    const [beanSelection, setBeanSelection] = useState(2);
    const [persons, setPersons] = useState(defaults.serves);
    const [timesPerWeek, setTimesPerWeek] = useState(2);
    const [years, setYears] = useState(1);
    const [nutricionDetailsVisibility, setNutricionDetailsVisibility] = useState(false);
    const [meatPricePerKilo, setMeatPricePerKilo] = useState(data.meats[meatSelection].price.toFixed(2));
    const [beanPricePerKilo, setBeanPricePerKilo] = useState(data.beans[beanSelection].price.toFixed(2));
    const [beanCo2PerKilo, setBeanCo2PerKilo] = useState(data.beans[beanSelection].co2);
    const [meatCo2PerKilo, setMeatCo2PerKilo] = useState(data.meats[meatSelection].co2);
    const [gramsPerPerson, setGramsPerPerson] = useState(data.meats[meatSelection].grams);
    const [savings, setSavings] = useState([0, 0]);

    const [recipes, setRecipes] = useState([]);

    useEffect(() => {
        //randomDish = dishes[getRandomInt(dishes.length)];
        fetch('/recipes.json')
            .then(response => response.json())
            .then(data => setRecipes(Object.values(data))); // Convert the object to an array
    }, []);

    useEffect(() => {
        // some value changed, reset visible result untill user presses the button
        setSavings([0, 0]);
    }, [gramsPerPerson, beanPricePerKilo, meatPricePerKilo, persons, timesPerWeek, years])

    const showDetails = (type) => {
        switch (type) {
            case 'explanation':
                setNutricionDetailsVisibility(<>
                    <h1>Bereken je boonus</h1>
                    <p>Peulvruchten zoals witte en bruine bonen, kidneybonen, kikkererwten en linzen zijn niet alleen lekker, maar ook goed voor je gezondheid en het milieu.Ze zijn een bron van plantaardige eiwitten en hebben een lage klimaatbelasting.Dit maakt ze een smakelijk, duurzaam en betaalbaar alternatief voor een dagje zonder vlees.</p>

                    <p>Peulvruchten staan in de Schijf van Vijf van het Voedingscentrum. Ze verlagen je LDL-cholesterol, wat helpt om je bloedvaten gezond te houden. Daarnaast bevatten ze veel vezels en net als vlees eiwit, ijzer en vitamine B1. Peulvruchten zijn een bron van plantaardige eiwitten. Ook zorgen bonen voor een lagere CO<sub>2</sub>-uitstoot dan de productie van vlees en zijn daarmee minder milieubelastend.Het Voedingscentrum geeft dan ook aan dat peulvruchten een gezonde en duurzame vervanger zijn op een dagje zonder vlees (bron: <a target="_blank" href="https://www.voedingscentrum.nl/encyclopedie/peulvruchten.aspx#blokwat-is-het-voedingsadvies-voor-peulvruchten">Peulvruchten | Voedingscentrum</a>). Ze zijn bovendien ook nog eens erg lekker en veelzijdig! Bereken eenvoudig wat je kunt besparen door de switch van vlees naar bonen in geld én CO<sub>2</sub>-uitstoot en ga zelf aan de slag!</p>
                </>
                );
                break;
            case 'savings':
                setNutricionDetailsVisibility(<>
                    <h2>Besparing</h2>
                    <p>Door vlees te vervangen met bonen kun je euro's besparen doordat bonen goedkoper zijn dan vlees. Met de Boonus-calculator maken we inzichtelijk hoeveel je hiermee bespaart. De besparing in euro's wordt berekend door de gemiddelde kiloprijs van een vleessoort af te trekken van de gemiddelde kiloprijs van een boonsoort. Dit getal wordt vermenigvuldigd met de variabelen: gram per persoon, aantal personen, aantal dagen in de week (inclusief het aantal weken in een jaar) en aantal jaren.</p>
                    <p>Liever zelf een prijs per kg invullen? Dat kan. Vul de kiloprijs in waarvoor jij vlees- en/of boonsoort koopt en zie wat jouw persoonlijke besparing is.</p>

                    <p>Meer informatie over de berekening vind je <a href="#" onClick={() => showDetails("disclaimer")}>hier</a>.</p>
                </>);
                break;
            case 'disclaimer':
                setNutricionDetailsVisibility(<>
                    <h1>Disclaimer</h1>
                    <p>Bereken je Boonus is een initiatief van HAK. De besparingsinformatie en -berekeningen die hier worden gepresenteerd zijn illustratief en zijn gebaseerd op aannames en gemiddelde waarde. De werkelijke besparingen kunnen variëren afhankelijk van diverse factoren die buiten onze controle liggen. De tool is met de grootste zorg ontwikkeld, maar de uitkomsten zijn onder voorbehoud van mogelijke fouten en er kunnen geen rechten aan worden ontleend.</p>

                    <p>Om de tool zo transparant mogelijk te maken kunnen bezoekers zelf aanpassingen maken in de variabelen zoals de prijs en het aantal gram. De ingevulde data worden niet opgeslagen of gebruikt voor overige doeleinden. </p>

                    <p>Hieronder lees je meer achtergrondinformatie over de tool.</p>

                    <h2>Plantaardige eiwitten</h2>
                    <p>Peulvruchten zijn een bron van plantaardige eiwitten, ideaal dus voor een dagje zonder vlees. Want door vlees af en toe te vervangen door peulvruchten krijgen consumenten meer vezels binnen en minder verzadigd vet.</p>

                    <p>Bang voor een te kort aan eiwit? Geen zorgen. De Gezondheidsraad stelt dat Nederlanders doorgaans al voldoende eiwitten eten en zij zich daarom überhaupt geen zorgen hoeven te maken over een tekort aan eiwitten als zij regelmatig bonen op het menu zetten in plaats van vlees. Het Voedingscentrum geeft dan ook aan dat peulvruchten een gezonde en duurzame vervanger zijn voor vlees.</p>


                    <h2>Geldbesparing</h2>
                    <p>De besparing in euro's wordt berekend op basis van de gemiddelde prijs in de categorie, inclusief online verkoop (zonder Picnic data) maar exclusief Lidl en Aldi, van de diverse peulvruchten en vleessoorten (exclusief biologisch). Deze data is gebaseerd op Circana beschikbare kwartaaldata (Q3 2024) en wordt elk kwartaal bijgewerkt.</p>

                    <p>De gemiddelde kiloprijs van een vleessoort wordt afgetrokken van de gemiddelde kiloprijs van een boonsoort. Dit getal wordt vermenigvuldigd met de variabelen: gram per persoon, aantal personen, aantal dagen in de week (inclusief het aantal weken in een jaar) en aantal jaren.</p>

                    <h2>Milieubesparing</h2>
                    <p>Door vlees te vervangen met bonen kun je de negatieve impact op het milieu (uitgedrukt in kg CO<sub>2</sub>-eq) verminderen. De milieubesparing wordt berekend door de CO<sub>2</sub> footprint van vlees af te trekken van de CO<sub>2</sub> footprint van bonen (beiden uitgedrukt in kg CO<sub>2</sub>-eq per kg product). Dit getal wordt vermenigvuldigd met de variabelen: kilogram per persoon, aantal personen, aantal dagen in de week (inclusief het aantal weken in een jaar) en aantal jaren. </p>

                    <p>De CO<sub>2</sub> footprint is gebaseerd op data van de LiveLCA. Vanwege een gebrek aan openbare specifieke data hebben we twee aannames gedaan:</p>
                    <ul>
                        <li>De CO<sub>2</sub> footprint van gerookte kip is gelijkgesteld aan die van kipfilet.</li>
                        <li>De CO<sub>2</sub> footprint van bruine bonen is gelijkgesteld aan die van bonen in het algemeen. </li>
                    </ul>

                    <h2>Milieubesparing in gereden kilometers</h2>
                    <p>De milieuwinst in kg CO<sub>2</sub>-eq wordt ook uitgedrukt in het aantal kilometers dat je kunt rijden met een gemiddeld voertuig, om het verschil aansprekender te maken. Het aantal kilometer is gelijk aan de milieuwinst in kg CO<sub>2</sub>-eq delen door kg CO<sub>2</sub>-eq uitstoot die gepaard gaat per kilometer voor een gemiddeld personenvoertuig, op basis data van CE Delft (2022). In dit gemiddelde zijn de volgende soorten personenvoertuigen meegenomen: benzine, diesel, plug-in hybride-benzine, plug-in hybride, diesel, LPG, CNG, elektrisch en waterstof. </p>

                    <p>Heb je nog een vraag? Dan kun je deze <a href="https://hak.nl/contact/stel-je-vraag" target="_blank">hier</a> stellen.</p>

                </>);
                break;
            case 'environment':
                setNutricionDetailsVisibility(<>
                    <h2>Milieuwinst</h2>
                    <p>Bonen zorgen voor een lagere CO<sub>2</sub>-uitstoot dan de productie van vlees en daarom is de consumptie van bonen als alternatief voor vlees minder milieubelastend. De milieubesparing die ontstaat door de switch van vlees naar bonen wordt uitdrukt in kilogrammen CO<sub>2</sub>-equivalenten (CO<sub>2</sub>-eq) op basis van data van de <a target="_blank" href="https://livelca.com/">LiveLCA</a> database. Daarnaast wordt het verschil ook uitgedrukt in kilometers om het verschil visueler te maken. Hiervoor is de uitstoot uitgedrukt in het aantal gereden kilometers met een gemiddeld voertuig volgens <a target="_blank" href="https://ce.nl/wp-content/uploads/2023/02/CE_Delft_210506_STREAM_Personenvervoer_2022_DEF.pdf">CE Delft (2022)</a>.</p>

                    <p>Er is geen specifieke data beschikbaar voor gerookte kip en bruine bonen. Daarom is de footprint voor gerookte kip gebaseerd op kip en de footprint van bruine bonen op bonen in het algemeen. </p>
                    <p>Meer informatie over de berekening vind je <a href="#" onClick={() => showDetails("disclaimer")}>hier</a>.</p>
                </>);
                break;
            case 'gramPerPerson':
                setNutricionDetailsVisibility(<>
                    <h2>Gemiddeld aantal gram per persoon</h2>
                    <p>Het aantal gram wordt automatisch ingevuld op basis van een inschatting van een portie per persoon.Om een inschatting te maken hebben wij gebruik gemaakt van de portiegroottes van het Voedingscentrum en portie-online van het RIVM.In de Boonus-calculator gaan wij ervan uit dat dezelfde hoeveelheid vlees wordt vervangen door dezelfde hoeveelheid peulvruchten en dat alle personen evenveel consumeren.</p>

                    <p>Natuurlijk kan het zo zijn dat jij/jouw gezin wat meer of minder eet. Je kunt het grammage per persoon daarom aanpassen in de hoeveelheid die jij gebruikt. </p>
                </>);
                break;
            case 'pricePerKg-meats':
                setNutricionDetailsVisibility(<>
                    <h2>Gemiddelde prijs per kg</h2>
                    <p>De besparing in euro's wordt berekend op basis van de gemiddelde prijs in de categorie, inclusief online verkoop (zonder Picnic data) maar exclusief Lidl en Aldi, van de diverse peulvruchten en vleessoorten (exclusief biologisch). Deze data is gebaseerd op Circana beschikbare kwartaaldata (Q3 2024) en wordt elk kwartaal bijgewerkt.</p>

                    <p>Liever zelf een prijs per kg invullen? Dat kan. Vul de kiloprijs in waarvoor jij vlees koopt en zie wat jouw persoonlijke besparing is.</p>
                </>);
                break;
            case 'pricePerKg-beans':
                setNutricionDetailsVisibility(<>
                    <h2>Gemiddelde prijs per kg</h2>
                    <p>De besparing in euro's wordt berekend op basis van de gemiddelde prijs in de categorie, inclusief online verkoop (zonder Picnic data) maar exclusief Lidl en Aldi, van de diverse peulvruchten en vleessoorten (exclusief biologisch). Deze data is gebaseerd op Circana beschikbare kwartaaldata (Q3 2024) en wordt elk kwartaal bijgewerkt.</p>

                    <p>Liever zelf een prijs per kg invullen? Dat kan. Vul de kiloprijs in waarvoor jij peulvruchten koopt en zie wat jouw persoonlijke besparing is.</p>
                </>);
                break;
            default:
                setNutricionDetailsVisibility(false);
        }
    };

    const handleClickRecipe = (recipe) => {
        window.open(recipe.recipe_link, '_blank');
    }

    const handleMeatSelection = (event) => {
        const newValue = event.target.value;

        setMeatPricePerKilo(data.meats[newValue].price);
        setMeatCo2PerKilo(data.meats[newValue].co2);
        setGramsPerPerson(data.meats[newValue].grams);
        setMeatSelection(newValue); // Update the state
        //event.target.parentNode.dataset.meatSelection = newValue;
    }

    const handleBeanSelection = (event) => {
        const newValue = event.target.value;
        setBeanPricePerKilo(data.beans[newValue].price);
        setBeanCo2PerKilo(data.beans[newValue].co2);
        setBeanSelection(newValue); // Update the state
        //event.target.parentNode.dataset.beanSelection = newValue;
    }

    /* grow inputfield on input if needed: https://css-tricks.com/auto-growing-inputs-textareas/ */
    const handleInputMeatPrice = (event, digits = null) => {
        let newValue = parseFloat(event.target.value);
        if (digits) {
            newValue = newValue.toFixed(digits);
        }
        setMeatPricePerKilo(newValue); // Update the state
        //event.target.parentNode.dataset.value = newValue;
    };

    const handleInputBeanPrice = (event, digits = null) => {
        let newValue = parseFloat(event.target.value);
        if (digits) {
            newValue = newValue.toFixed(digits);
        }
        setBeanPricePerKilo(newValue); // Update the state
        //event.target.parentNode.dataset.beanPrice = newValue;
    };

    const handleInputGrams = (event) => {
        const newValue = event.target.value;
        setGramsPerPerson(newValue); // Update the state
        //event.target.parentNode.dataset.gramsPerPerson = newValue;
    };
    function handleCounterChange(type, value) {
        if (type === 'persons') {
            const newPersons = Number(persons) + value;
            setPersons(newPersons > 0 ? newPersons : 1);
        } else if (type === 'timesPerWeek') {
            const newTimesPerWeek = Number(timesPerWeek) + value;
            setTimesPerWeek(newTimesPerWeek > 0 ? newTimesPerWeek : 1);
        } else if (type === 'years') {
            const newYears = Number(years) + value;
            setYears(newYears > 0 ? newYears : 1);
        }
    };

    function calculateSavings() {
        setSavings([yearlySavings, co2Savings]);
        setTimeout(() => {
            if (resultRef.current) {
                resultRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                    inline: "start"
                });
            }

        }, 0);
    }

    function getRandomInt(max) {
        return Math.floor(Math.random() * max);
    }

    const yearlySavings = useMemo(() => (

        (
            // H: =(G3*B3)/1000
            // I: =H3/C3
            ((Number(gramsPerPerson) * (Number(meatPricePerKilo) / 1000)) * persons) -

            ((Number(gramsPerPerson) * (Number(beanPricePerKilo) / 1000)) * persons)

        ) * timesPerWeek * years * 52
    ).toFixed(2), [gramsPerPerson, beanPricePerKilo, meatPricePerKilo, persons, timesPerWeek, years]);

    const co2Savings = (
        (
            (
                (Number(gramsPerPerson) / 1000) *
                Number(meatCo2PerKilo)
            ) -
            (
                (Number(gramsPerPerson) / 1000) *
                Number(beanCo2PerKilo)
            )
        ) * persons * timesPerWeek * years * 52
    ).toFixed(2);


    return (
        <div className="app home">
            <div className="popup" style={{ backgroundImage: `url(${BgPattern})` }}>
                <div className="calculation-card">
                    <div className="card-top">
                        <h1>Bereken je boonus</h1>
                        <div style={{ display: 'flex', position: 'relative' }} className="tooltip protein-tooltip" onClick={() => showDetails('explanation')}>
                            <p className="recipe-description">Bonen zitten vol plantaardige eiwitten. Perfect dus voor een dagje zonder vlees. Vervang vlees door peulvruchten en bereken hier wat je kunt besparen in geld én CO<sub>2</sub>-uitstoot.</p>
                            <InfoIcon />
                        </div>
                    </div>
                    <div className="card-bottom">
                        {/* <h3>Wat is jouw voordeel op jaarbasis?</h3> */}
                        <div className="counter">
                            <label className="results">
                                <span className="counter-label">selecteer vlees </span>
                                <div class="select-wrapper">
                                    <select onChange={handleMeatSelection}>
                                        {
                                            Object.keys(data.meats).map((meat, i) => {
                                                return (
                                                    <option selected={meatSelection == meat} key={meat} value={meat}>{data.meats[meat].name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </label>
                        </div>
                        <div className="counter currency">
                            <label className="input-sizer results">
                                <span className="counter-label tooltip" onClick={() => showDetails('pricePerKg-meats')}>gem. prijs per kg <InfoIcon /></span>
                                <input type="number" className="highlight" onInput={(e) => handleInputMeatPrice(e)} onBlur={(e) => handleInputMeatPrice(e, 2)} size="4" value={meatPricePerKilo} />
                            </label>
                        </div>
                        <div className="counter">

                            <label className="input-sizer results">
                                <span className="counter-label tooltip" onClick={() => showDetails('gramPerPerson')}>gem. gram per persoon <InfoIcon /></span>
                                <input type="number" step="10" className="highlight" onInput={handleInputGrams} size="4" value={gramsPerPerson} />
                            </label>
                        </div>

                        <div className="counter">
                            <label className="results">
                                <span className="counter-label">vervangen door </span>

                                <div class="select-wrapper">
                                    <select onChange={handleBeanSelection}>
                                        {
                                            Object.keys(data.beans).map((bean, i) => {
                                                return (
                                                    <option selected={beanSelection == bean} value={bean} key={bean}>{data.beans[bean].name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </div>
                            </label>
                        </div>
                        <div className="counter currency">
                            <label className="input-sizer results">
                                <span className="counter-label tooltip" onClick={() => showDetails('pricePerKg-beans')}>gem. prijs per kg <InfoIcon /></span>
                                <input type="number" className="highlight" onInput={(e) => handleInputBeanPrice(e)} onBlur={(e) => handleInputBeanPrice(e, 2)} size="4" value={beanPricePerKilo} />
                            </label>
                        </div>

                        <div className="counter">
                            <span className="counter-label">personen</span>
                            <div className="buttons-wrapper">
                                <button
                                    onClick={() => handleCounterChange('persons', -1)}
                                    className={persons <= 1 ? "disabled" : ""}
                                    disabled={persons <= 1}>-</button>
                                <div className="amount">{persons}</div>
                                <button onClick={() => handleCounterChange('persons', 1)}>+</button>
                            </div>
                        </div>
                        <div className="counter">
                            <span className="counter-label">x per week</span>
                            <div className="buttons-wrapper">
                                <button
                                    onClick={() => handleCounterChange('timesPerWeek', -1)}
                                    className={timesPerWeek <= 1 ? "disabled" : ""}
                                    disabled={timesPerWeek <= 1}>-</button>
                                <div className="amount">{timesPerWeek}</div>
                                <button onClick={() => handleCounterChange('timesPerWeek', 1)}
                                    className={timesPerWeek >= 7 ? "disabled" : ""}
                                    disabled={timesPerWeek >= 7}

                                >+</button>
                            </div>
                        </div>
                        <div className="counter">
                            <span className="counter-label">aantal jaar</span>
                            <div className="buttons-wrapper">
                                <button
                                    onClick={() => handleCounterChange('years', -1)}
                                    className={years <= 1 ? "disabled" : ""}
                                    disabled={years <= 1}>-</button>
                                <div className="amount">{years}</div>
                                <button onClick={() => handleCounterChange('years', 1)}>+</button>
                            </div>
                        </div>

                        <div class="button-calculate-container">
                            <div className="disclaimer smaller tooltip" onClick={() => showDetails('disclaimer')}>disclaimer</div>

                            <button class="button-calculate" onClick={calculateSavings}>bereken nu</button>
                        </div>
                        <RecipeDetailPopup content={nutricionDetailsVisibility} onClose={showDetails} />
                    </div>

                </div>
                <div className="right-section" style={savings[0] === 0 ? { backgroundImage: `url('${randomDish}')` } : {}}>
                    {savings[0] === 0 &&
                        <div className="dish">
                            <img src={ImageTitle} className="title" />

                        </div>
                    }
                    {savings[0] !== 0 &&
                        <>
                            <div class="results" ref={resultRef}>
                                <div className="icon-block euro-savings tooltip money-tooltip" onClick={() => showDetails('savings')}>

                                    <IconSavings class="icon" />
                                    <div class="text">
                                        <InfoIcon />
                                        <span>besparing</span>
                                    </div>
                                    <div className="euro-savings">€ {Number(savings[0]).toFixed(2).toLocaleString('nl')}
                                    </div>

                                </div>
                                <div className="icon-block icon-block-co2 tooltip environment-tooltip" onClick={() => showDetails('environment')}>
                                    <IconEnvironment class="icon" />

                                    <div className="text">
                                        <InfoIcon />
                                        Milieuwinst = {(savings[1] / 1).toFixed(1).toString().replace('.', ',')}<span class="smaller">kg</span> CO<sub>2</sub>-<span class="smaller">eq</span><br />
                                        <div class="small-text"> Dat is ongeveer gelijk aan {(savings[1] / co2ToKm).toFixed(0)} km rijden met de auto</div>
                                    </div>
                                </div>
                            </div>
                            <RecipeCards recipes={recipes} onCardClick={handleClickRecipe} />

                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default App;
